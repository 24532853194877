
<div class="header">
  <h1>Tarocchi</h1>
</div>

<div id="pesca-una-carta">
  <div class="container">
    <h2>Pesca Una Carta</h2>
    <app-pesca-una-carta-classico
      (cartaClick)="cartaScoperta = $event">
    </app-pesca-una-carta-classico>
  </div>
</div>

<div id="tema-del-giorno">
  <div class="container">
    <h2>Tema Del Giorno</h2>
    <p>Una carta per ogni casa del tuo tema natale di oggi.</p>
    <app-gioco-geometrico
      giocoGeometricoNumLatiPredefinito="12"
      tutteLeCarteScoperte="true"
      mostraRimescolaIlMazzo="false"
      (cartaClick)="cartaScoperta = $event"
    ></app-gioco-geometrico>
  </div>
</div>

<div id="carta-pescata" *ngIf="cartaScoperta>=0">
  <div class="container">
    <div class="inner">
      <div class="carta" [ngStyle]="{'background-image': 'url(/assets/tarocchi%20marsigliesi/'+cartaScoperta+'.jpeg)'}">

      </div>
      <div class="content">
        <div class="nome">{{cartaScoperta?cartaScoperta:''}} {{infoCarte[cartaScoperta].nome}}</div>
        <div class="descrizione">
          {{tarocchiDescrizione[cartaScoperta]}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <div class="container">
    <div>
      <p>
        Con la collaborazione di <a href="https://www.instagram.com/perleastrologiche/">Riccardo Pruner</a> e <a href="https://www.stefanocetani.com/">Stefano Cetani</a>.
      </p>
      <p>
        Se questa pagina ti è stata utile puoi contribuirne allo sviluppo sostenendomi con una donazione su PayPal.<br>
        <a href="https://paypal.me/mirkio?country.x=IT&locale.x=it_IT">Il mio conto PayPal</a>
      </p>
      <p>
        Per qualsiasi informazione, collaborazione, richiesta o segnalazione, puoi scrivermi alla mail <a href="mailto:mirko.lance@gmail.com">mirko.lance@gmail.com</a>.
      </p>
      <div>Mirko Lancerotto</div>
      <div class="socials">
        <a href="http://www.mirkolancerotto.it/" class="fa fa-globe"></a>
        <a href="https://www.facebook.com/mirko.lancerotto/" class="fab fa-facebook"></a>
        <a href="https://www.instagram.com/mirko.lancerotto/" class="fab fa-instagram"></a>
        <a href="https://www.youtube.com/@mirkolancerotto" class="fab fa-youtube"></a>
        <a href="https://www.tiktok.com/@mirkolancerotto" class="fab fa-tiktok"></a>
      </div>
    </div>
  </div>
</div>
