import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarocchi-tema-astrale',
  templateUrl: './tarocchi-tema-astrale.component.html',
  styleUrls: ['./tarocchi-tema-astrale.component.sass']
})
export class TarocchiTemaAstraleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
